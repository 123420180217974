import React from 'react';
  import {
    Box,
    TextField,
    Button,
    Typography,
    Grid,
    Divider,
    CircularProgress,
    IconButton,
    Tooltip,
    InputAdornment,
  } from '@mui/material';
  import CopyIcon from '@mui/icons-material/ContentCopy';
  import CookieIcon from '@mui/icons-material/Cookie';
  import Swal from 'sweetalert2';


function CookieModifier() {
  const [originalCookies, setOriginalCookies] = React.useState('');
  const [generatedCookies, setGeneratedCookies] = React.useState('');
  const [isGenerating, setIsGenerating] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [expirationDate, setExpirationDate] = React.useState('');


  const handleGenerateClick = async () => {
    setIsGenerating(true);
    setError(null);

    try {
      await new Promise(resolve => setTimeout(resolve, 1000));

      const parsedCookies = JSON.parse(originalCookies);
      parsedCookies.forEach(cookie => {
        if (expirationDate) {
          const date = new Date(expirationDate);
          cookie.expirationDate = Math.floor(date.getTime() / 1000);

        }
      });
      const updatedCookieString = JSON.stringify(parsedCookies);

      setGeneratedCookies(updatedCookieString);
      Swal.fire({
        title: 'Cookies Modified!',
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      });
      setOriginalCookies('');
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generatedCookies)
      .then(() => {
        Swal.fire({
            title: 'Copied to clipboard!',
            icon: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });
      })
      .catch(error => {
        console.error('Error copying to clipboard:', error);
        Swal.fire({
            title: 'Unknown Error Occured!',
            icon: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });

      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 0, padding: 0 }}>
      <Typography variant="h3" sx={{ mb: 4, mt: 4 }}>Cookie Keeper</Typography>
      <Grid container spacing={2} sx={{ width: '90%' }}>
        <Grid item xs={6}>
          
          <TextField
            label="Paste your cookies JSON here"
            multiline
            rows={8}
            value={originalCookies}
            onChange={e => setOriginalCookies(e.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" aria-label="cookie" sx={{ mr: 1 }}>
                    <CookieIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          
          <TextField
            label="Modified cookies will be shown here."
            multiline
            rows={8}
            value={generatedCookies}
            disabled
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Copy to clipboard">
                    <IconButton onClick={handleCopyClick}>
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          
          <TextField
            label="Expiration date"
            type="date"
            value={expirationDate}
            onChange={(e) => setExpirationDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth 
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 4 }} />
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateClick}
        disabled={isGenerating}
        fullWidth 
        sx={{ mt: 2, width: '88%'}}
      >
        {isGenerating ? (
          <CircularProgress size={24} />
        ) : (
          'MODIFY COOKIES'
        )}
      </Button>
  
      {error && <Typography variant="body2" color="error" sx={{ mt: 2 }}>{error.message}</Typography>}
      <Typography variant="body2" sx={{ mt: 4, textAlign: 'center', fontSize: '14px' }}>
    Made by Sanjith with ❤️ 
  </Typography>
    </Box>
  );
}

export default CookieModifier;
